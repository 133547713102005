<template>
  <div class="home_page">
    <!-- 二级导航 -->
    <div class="home_slider">
      <div
        class="item tab active"
        style="border-radius: 0.16rem 0px 0px 0.16rem"
      >
        <span class="pic1-1"></span>
        <span class="pic1-2"></span>
        督导组
      </div>
      <div class="item tab" @click="toJump('/admin/testconter/pesContent')">
        <span class="pic2-1"></span>
        <span class="pic2-2"></span>
        测试报告
      </div>
      <div class="item tab" @click="toJump('/sport')">
        <span class="pic3-1"></span>
        <span class="pic3-2"></span>
        小小运动会
      </div>
      <div class="item tab" @click="toJump('/train')">
        <span class="pic4-1"></span>
        <span class="pic4-2"></span>
        师资培训
      </div>
      <div class="item tab" @click="toJump('/shop')">
        <span class="pic5-1"></span>
        <span class="pic5-2"></span>
        商城
      </div>
      <div
        class="item tab"
        style="border-radius: 0px 0.16rem 0.16rem 0px"
        @click="toJump('/instructions')"
      >
        <span class="pic6-1"></span>
        <span class="pic6-2"></span>
        操作指引
      </div>
    </div>
    <div class="center_block">
      <div class="center_item tab" @click="$router.push({ path: 'integral' })">
        <span class="list_icon"></span>
        <span>我的积分</span>
      </div>
      <div class="center_item tab" @click="$router.push({ path: 'score' })">
        <span class="rank_icon"></span>
        <span>积分展示</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeIndex",
  components: {},
  mounted() {},
  data() {
    return {
      tabIndex: 0,
      list: [],
    };
  },
  created() {},
  methods: {
    toJump(path, param) {
      this.$router.push({
        path: path,
        query: {
          ...param,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table,
th,
td {
  border: 1px solid #c9c9c9;
  text-align: center;
}

th {
  background: #cc1a30;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  padding: 20px 0;
}

td {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  padding: 19px 0;
  color: #333333;
}

table {
  width: 1438px;
}

.center_block {
  display: block;
  width: 1434px;
  margin: 100px auto 0;
  @include flex(row, space-around, center);
  .center_item {
    @include flex();
    background: #c21820;
    color: #fff;
    font-size: 50px;
    margin-bottom: 100px;
    width: 465px;
    height: 169px;
    background: #c21820;
    box-shadow: 0px 5px 24px 0px rgba(20, 10, 21, 0.23);
    border-radius: 20px;
  }
}

.home_slider {
  @include flex();
  width: 100%;
  height: 125px;
  background: #e6e5e5;

  .item {
    width: 234px;
    height: 89px;
    box-sizing: border-box;
    background: #c2292b;
    font-size: 30px;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    color: #ffffff;
    margin-right: 6px;
    @include flex();

    .pic1-2,
    .pic2-2,
    .pic3-2,
    .pic4-2,
    .pic5-2,
    .pic6-2 {
      display: none;
    }

    .pic1-1,
    .pic2-1,
    .pic3-1,
    .pic4-1,
    .pic5-1,
    .pic6-1 {
      display: block;
    }
  }

  .item:last-child {
    margin-right: 0;
  }

  .active {
    font-family: Alibaba PuHuiTi;
    font-size: 30px;
    font-weight: bold;
    background: #fff;
    color: #cc1a30;
    box-sizing: border-box;
    border-bottom: solid 7px #cc1a30;

    .pic1-2,
    .pic2-2,
    .pic3-2,
    .pic4-2,
    .pic5-2,
    .pic6-2 {
      display: block;
    }

    .pic1-1,
    .pic2-1,
    .pic3-1,
    .pic4-1,
    .pic5-1,
    .pic6-1 {
      display: none;
    }
  }
}

.item:hover {
  font-size: 30px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  background: #fff;
  color: #cc1a30;
  border-bottom: solid 7px #cc1a30;

  .pic1-2,
  .pic2-2,
  .pic3-2,
  .pic4-2,
  .pic5-2,
  .pic6-2 {
    display: block;
  }

  .pic1-1,
  .pic2-1,
  .pic3-1,
  .pic4-1,
  .pic5-1,
  .pic6-1 {
    display: none;
  }
}

.pic1-1 {
  @include bg("../../assets/img/home/pic1-1.png");
  height: 36px;
  width: 44px;
  margin-right: 10px;
}

.pic1-2 {
  @include bg("../../assets/img/home/pic1-2.jpg");
  height: 36px;
  width: 44px;
  margin-right: 10px;
}

.pic2-1 {
  @include bg("../../assets/img/home/pic2-1.png");
  height: 39px;
  width: 37px;
  margin-right: 10px;
}

.pic2-2 {
  @include bg("../../assets/img/home/pic2-2.jpg");
  height: 39px;
  width: 37px;
  margin-right: 10px;
}

.pic3-1 {
  @include bg("../../assets/img/home/pic3-1.png");
  height: 37px;
  width: 37px;
  margin-right: 10px;
}

.pic3-2 {
  @include bg("../../assets/img/home/pic3-2.jpg");
  height: 37px;
  width: 37px;
  margin-right: 10px;
}

.pic4-1 {
  @include bg("../../assets/img/home/pic4-1.png");
  height: 38px;
  width: 43px;
  margin-right: 10px;
}

.pic4-2 {
  @include bg("../../assets/img/home/pic4-2.jpg");
  height: 38px;
  width: 43px;
  margin-right: 10px;
}

.pic5-1 {
  @include bg("../../assets/img/home/pic5-1.png");
  height: 34px;
  width: 35px;
  margin-right: 10px;
}

.pic5-2 {
  @include bg("../../assets/img/home/pic5-2.jpg");
  height: 34px;
  width: 35px;
  margin-right: 10px;
}

.pic6-1 {
  @include bg("../../assets/img/home/pic6-1.png");
  height: 37px;
  width: 37px;
  margin-right: 10px;
}

.pic6-2 {
  @include bg("../../assets/img/home/pic6-2.jpg");
  height: 37px;
  width: 37px;
  margin-right: 10px;
}
.list_icon {
  width: 66px;
  height: 72px;
  margin-right: 19px;
  @include bg("../../assets/img/home/score.png");
}
.rank_icon {
  width: 73px;
  height: 76px;
  margin-right: 19px;
  @include bg("../../assets/img/home/rank.png");
}
</style>
